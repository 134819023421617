import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  InvoiceResult,
  InvoiceType,
  PaymentSubInvoiceItem,
  TrelloBidDetail,
  TrelloSalesManagementTableData,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { removeComma } from "@sellernote/_shared/src/utils/common/string";
import { changeInvoiceResultToKr } from "@sellernote/_shared/src/utils/forwarding/admin/adminSettlement";
import {
  changeInvoiceTypeToKr,
  checkInvoiceStatus,
  checkIsCustomSended,
} from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import { TableBodyRow, TableHeadCell } from "../../../../../components/Table";

const NOT_AVAILABLE = "n/a";

type CellId = keyof TrelloSalesManagementTableData | "comment";

export default function useSalesManagementTableRows({
  trelloSalesManagementTableData,
  trelloDetailData,
  handleFileDownload,
  handleInvoiceIssueClick,
  setInvoiceType,
  setShowsCancelInvoiceModal,
  setBillingAmount,
  setInvoiceId,
  setShowsDepositHistoryModal,
  setPaymentInvoiceId,
  handleModalOpen,
  setIsRequestedAndNotIssued,
}: {
  trelloSalesManagementTableData: TrelloSalesManagementTableData[];
  trelloDetailData: TrelloBidDetail;
  handleFileDownload: (downloadKey: string | undefined) => void;
  handleInvoiceIssueClick: (invoiceId: number | undefined) => () => void;
  setInvoiceType: (value: InvoiceType) => void;
  setShowsCancelInvoiceModal: (value: boolean) => void;
  setBillingAmount: (value: number) => void;
  setInvoiceId: (value: number) => void;
  setShowsDepositHistoryModal: (value: boolean) => void;
  setPaymentInvoiceId: (value: number) => void;
  handleModalOpen: () => void;
  setIsRequestedAndNotIssued: (val: boolean) => void;
}) {
  const history = useHistory();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [_, setSessionInvoiceId] = useSessionStorage<string | null>(
    "invoiceId"
  );

  const handleCancelInvoiceModalOpen = useCallback(
    ({
      domain,
      isRequestedAndNotIssued,
    }: {
      domain: InvoiceType;
      isRequestedAndNotIssued: boolean;
    }) => {
      setIsRequestedAndNotIssued(isRequestedAndNotIssued);
      setInvoiceType(domain);
      setShowsCancelInvoiceModal(true);
    },
    [setInvoiceType, setIsRequestedAndNotIssued, setShowsCancelInvoiceModal]
  );

  const getDepositStatusInfo = (invoiceResult: InvoiceResult | undefined) => {
    if (!invoiceResult) {
      return (
        <Typography variant="body2" component={"span"} color="error">
          입금대기
        </Typography>
      );
    }

    if (invoiceResult === "complete") {
      return (
        <Typography variant="body2" component={"span"} color="error">
          입금완료
        </Typography>
      );
    }

    return (
      <>
        <Typography variant="body2" component={"span"}>
          입금
        </Typography>

        <Typography variant="body2" component={"span"} color="error">
          ({changeInvoiceResultToKr(invoiceResult)})
        </Typography>
      </>
    );
  };

  const getInvoiceResult = useCallback(
    ({
      paymentSubInvoice,
      invoiceResult,
      finalPrice,
      invoiceId,
      isSended,
    }: {
      paymentSubInvoice: PaymentSubInvoiceItem[];
      invoiceResult: InvoiceResult | undefined;
      finalPrice: string;
      invoiceId: number;
      isSended: boolean | undefined;
    }) => {
      if (!isSended) {
        return "-";
      }

      if (isEmptyObjectOrArray(paymentSubInvoice)) {
        return (
          <>
            {getDepositStatusInfo(invoiceResult)}

            <IconButton
              size="small"
              onClick={() => {
                setBillingAmount(Number(finalPrice));
                setInvoiceId(invoiceId);
                setShowsDepositHistoryModal(true);
              }}
            >
              <ListIcon fontSize="medium" />
            </IconButton>
          </>
        );
      }
      if (invoiceResult) {
        return paymentSubInvoice.map((v, index) => {
          return (
            <Box
              key={v.id}
              sx={{
                pt: index === 0 ? undefined : 1,
                pb: index === 0 ? undefined : 1,
              }}
            >
              {getDepositStatusInfo(invoiceResult)}

              {/* 첫번째 항목에만 버튼을 보여준다. */}
              {index === 0 && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setBillingAmount(Number(finalPrice));
                    setInvoiceId(v.invoiceId);
                    setShowsDepositHistoryModal(true);
                  }}
                >
                  <ListIcon fontSize="medium" />
                </IconButton>
              )}
            </Box>
          );
        });
      }

      return "-";
    },
    [setBillingAmount, setInvoiceId, setShowsDepositHistoryModal]
  );

  const handleMoveToTradingStatement = useCallback(
    (invoiceId: number | undefined, invoiceType: InvoiceType) => {
      return () => {
        // 수입 수출인지 구분
        const isImport = trelloDetailData.isImport;

        // 운영 관리 페이지 복귀 시 자동 오픈을 위한 전역 데이터
        setTrelloCardId(trelloDetailData.id);

        // 거래명세서에서 페이지에서 사용할 명세서 고유 아이디 저장
        setSessionInvoiceId(invoiceId ? invoiceId.toString() : null);
        history.push(
          `/tradingStatement/${invoiceType}/${trelloDetailData.id}/${
            isImport ? "import" : "export"
          }`
        );
      };
    },
    [
      history,
      setSessionInvoiceId,
      setTrelloCardId,
      trelloDetailData.id,
      trelloDetailData.isImport,
    ]
  );

  const getCommentButton = useCallback(
    (
      paymentSubInvoice: PaymentSubInvoiceItem[],
      invoiceType: InvoiceType,
      invoiceId: number | undefined
    ) => {
      if (!isEmptyObjectOrArray(paymentSubInvoice) && invoiceId) {
        return paymentSubInvoice.map((v) => {
          return (
            <Button
              variant="outlined"
              key={v.id}
              onClick={() => {
                setInvoiceType(invoiceType);
                setInvoiceId(invoiceId);
                setPaymentInvoiceId(v.paymentInvoiceId);
                handleModalOpen();
              }}
            >
              {v.paymentInvoice.comment ? "확인" : "등록"}
            </Button>
          );
        });
      }

      return "-";
    },
    [handleModalOpen, setInvoiceId, setInvoiceType, setPaymentInvoiceId]
  );
  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    if (APP_NAME === "partner-admin") {
      return [
        {
          id: "invoiceType",
          disablePadding: false,
          label: "구분",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "downloadKey",
          disablePadding: false,
          label: "다운로드",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "totalPrice",
          disablePadding: false,
          label: "공급가액",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "vatPrice",
          disablePadding: false,
          label: "부가세",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "taxFee",
          disablePadding: false,
          label: "대납세금",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "finalPrice",
          disablePadding: false,
          label: "합계",
          width: 120,
          verticalAlign: "middle",
        },
      ];
    }

    return [
      {
        id: "invoiceType",
        disablePadding: false,
        label: "구분",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "downloadKey",
        disablePadding: false,
        label: "다운로드",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "isCustomSended",
        disablePadding: false,
        label: "발송처리",
        width: 100,
        verticalAlign: "middle",
      },
      {
        id: "isTemporary",
        disablePadding: false,
        label: "상태",
        width: 100,
        verticalAlign: "middle",
      },
      {
        id: "totalPrice",
        disablePadding: false,
        label: "공급가액",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "vatPrice",
        disablePadding: false,
        label: "부가세",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "taxFee",
        disablePadding: false,
        label: "대납세금",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "finalPrice",
        disablePadding: false,
        label: "합계",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "name",
        disablePadding: false,
        label: "이름",
        width: 100,
        verticalAlign: "middle",
      },
      {
        id: "lastUpdatedAt",
        disablePadding: false,
        label: "업데이트 일시",
        width: 110,
        verticalAlign: "middle",
      },
      {
        id: "invoiceResult",
        disablePadding: false,
        label: "입금(내역)",
        width: 150,
      },
      {
        id: "comment",
        disablePadding: false,
        label: "입금메모",
        width: 100,
      },
      {
        id: "issuedInvoices",
        disablePadding: false,
        label: "계산서",
        width: 120,
      },
      {
        id: "isIssued",
        disablePadding: false,
        label: "발행",
        width: 120,
        verticalAlign: "middle",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!trelloSalesManagementTableData) return [];

    if (APP_NAME === "partner-admin") {
      return trelloSalesManagementTableData.map((v) => {
        const row: TableBodyRow<CellId> = {
          invoiceType: (
            <Button
              variant="text"
              disabled={true}
              onClick={handleMoveToTradingStatement(v.invoiceId, v.invoiceType)}
            >
              {changeInvoiceTypeToKr(v.invoiceType)}
            </Button>
          ),
          downloadKey: (
            <Button
              size="small"
              disabled={!v.downloadKey}
              variant="outlined"
              onClick={() => handleFileDownload(v.downloadKey)}
            >
              다운로드
            </Button>
          ),
          totalPrice: v.totalPrice,
          vatPrice: v.vatPrice,
          taxFee: v.taxFee,
          finalPrice: v.finalPrice,
        };

        return row;
      });
    }

    return trelloSalesManagementTableData.map((v) => {
      /** 대납세금이 합계와 동일한 매출항목은 발행하지 않음 */
      const isIssueUnnecessary = (() => {
        if (!v.taxFee || !v.finalPrice) return false;

        return v.taxFee === v.finalPrice;
      })();

      const row: TableBodyRow<CellId> = {
        invoiceType: (
          <Button
            variant="text"
            onClick={handleMoveToTradingStatement(v.invoiceId, v.invoiceType)}
          >
            {changeInvoiceTypeToKr(v.invoiceType)}
          </Button>
        ),
        downloadKey: (
          <Button
            size="small"
            disabled={!v.downloadKey}
            variant="outlined"
            onClick={() => handleFileDownload(v.downloadKey)}
          >
            다운로드
          </Button>
        ),
        isCustomSended: checkIsCustomSended({
          isCustomSended: v.isCustomSended,
          isSended: v.isSended,
          invoiceType: v.invoiceType,
        }),
        isTemporary: checkInvoiceStatus(v.isTemporary),
        /** 공급가액 */
        totalPrice: v.totalPrice,
        /** 부가세 */
        vatPrice: v.vatPrice,
        /** 대납세금 */
        taxFee: v.taxFee,
        /** 합계 */
        finalPrice: v.finalPrice,
        name: v.name,
        lastUpdatedAt: toFormattedDate(v.lastUpdatedAt, "YYYY.MM.DD HH:mm:ss"),

        invoiceResult: getInvoiceResult({
          paymentSubInvoice: v.paymentSubInvoice || [],
          invoiceResult: v.invoiceResult,
          finalPrice: removeComma(v.finalPrice || 0),
          invoiceId: v.invoiceId as number,
          isSended: v.isSended,
        }),

        comment: getCommentButton(
          v.paymentSubInvoice || [],
          v.invoiceType,
          v.invoiceId
        ),

        /**
         * issuedInvoices(매출관리 > 계산서) row 조건 정리
         * 발행이 완료된 경우 -> 취소 요청
         * 발행이 완료되지 않은 경우 -> 발행 요청
         * 발행이 완료되지 않았으나, 대납세금이 합계와 같은 경우 -> n/a (#14065)
         */
        issuedInvoices: v.issuedInvoices ? (
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              handleCancelInvoiceModalOpen({
                domain: v.invoiceType,
                isRequestedAndNotIssued: Boolean(v.isRequestedAndNotIssued),
              });
              setInvoiceId(v.invoiceId ?? 0);
            }}
            disabled={v.isRequestCanceled}
          >
            취소 요청
          </Button>
        ) : isIssueUnnecessary ? (
          <Typography variant="h6" sx={{ ml: 2.7 }}>
            {NOT_AVAILABLE}
          </Typography>
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={handleInvoiceIssueClick(v.invoiceId)}
          >
            발행 요청
          </Button>
        ),

        isIssued: v.isRequestCanceled
          ? "취소 요청"
          : v.isIssued
          ? "발행 완료"
          : "-",
      };

      return row;
    });
  }, [
    trelloSalesManagementTableData,
    handleMoveToTradingStatement,
    handleFileDownload,
    getInvoiceResult,
    getCommentButton,
    handleInvoiceIssueClick,
    handleCancelInvoiceModalOpen,
    setInvoiceId,
  ]);

  return { headCells, rows };
}
